import { render, staticRenderFns } from "./CountDownTimer.vue?vue&type=template&id=c53bc3e4&scoped=true"
import script from "./CountDownTimer.vue?vue&type=script&lang=js"
export * from "./CountDownTimer.vue?vue&type=script&lang=js"
import style0 from "./CountDownTimer.vue?vue&type=style&index=0&id=c53bc3e4&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c53bc3e4",
  null
  
)

export default component.exports